import axios from 'axios';
import { showAlert } from './alert';

export const createProd = async (name, price, summary, description, imageCover) => {
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/products',
            data: {
                name,
                price,
                summary,
                description,
                imageCover 
            }
            
        });
        if (res.data.status === 'success') {
            showAlert('success', 'Admin successfully created a product');
            window.setTimeout(() =>{
                location.assign('/account');
            }, 500); 
          }
    } catch (err) {
        showAlert('error', err.response.data.message);
    }
}
