import axios from 'axios';
import { showAlert } from './alert';

export const deleteBtn = async (rout, id) =>{
    try {
        const res = await axios({
            method: 'DELETE',
            url: `/api/v1/${rout}/${id}`
        });
        if (res.data.status === 'success') {
            showAlert('success', 'Request successfully deleted!');
            window.setTimeout(() => {
                location.assign('/account');
                }, 1000);
        }
    } catch (err) {
        showAlert('error', err.response.data.message); 
    }
};