import axios from 'axios';
import { showAlert } from './alert'; 
//import keys from '../../config/keys'; 

// const stripe = Stripe('pk_test_51KYQgqJwlvOhdr40gHdwEAzVvwGzdDewk3UnSeGIaVCbJLfv68t5zOkbxWvV1qBewOeKrkmWGuK9DyrUjClzf3lf00QGhuyzqx');
// //keys.stripeKey

// export const bookProduct = async productId => {
//   try {
//     // 1) Get checkout session from API
//     const session = await axios(`/api/v1/bookings/checkout-session/${productId}`);   
//      //console.log(session);

//     // 2) Create checkout form + charge credit card
//     await stripe.redirectToCheckout({
//       sessionId: session.data.session.id 
//     });
//   } catch (err) {
//     //console.log(err);
//     showAlert('error', err); 
//   }
// };


export const bookProduct = async productId => {
  await axios.post(`/api/v1/bookings/checkout-session/${productId}`)
        .then(res =>{
          return res.data
        }).then(({url}) =>{
          window.location = url
        }).catch(err => {
          console.log(err)
        })
}

