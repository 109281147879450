import axios from 'axios';
import { showAlert } from './alert';

export const placeOrder = async productId => {
    try {
        const res = await axios({
            method: 'GET',
            url: `/api/v1/bookings/place-order/${productId}`
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Your Order has been placed!');
            window.setTimeout(() => {
            location.assign('/account');
            }, 1000); 
          }

    } catch (err) {
        showAlert('error', 'Something went wrong!');
        window.setTimeout(() => {
        location.assign('/product');
        }, 500);
    };
};