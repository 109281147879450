import axios from 'axios';
import { showAlert } from './alert';

export const messageForm = async (name, email, subject, message) =>{
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/messages',
            data: {
                name,
                email,
                subject,
                message
            }
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Message successfully sent!');
            window.setTimeout(() => {
            location.assign('/contact');
            }, 1000);
        }
    } catch (err) {
        showAlert('error', 'Something went wrong!');
    }
};