import '@babel/polyfill';
import { showAlert } from './alert';
import { login, logout } from './login';
import { signup } from './signup';
import { deleteBtn  } from './delete';
import { createProd } from './createProd';
import { reviewProd } from './review';
import { messageForm } from './message';
import { placeOrder } from './placeOrder';
import { bookProduct } from './stripe';

//DOM Elements
const loginForm = document.querySelector('.login-form'); 
const signupForm = document.querySelector('.form-signup'); 
const createProdForm = document.querySelector('.Create--form');
const reviewForm = document.querySelector('.review-form');
const msgForm = document.querySelector('.msg--form');
const logOutBtn = document.getElementById('logout-btn');
const deleteBookingBtn = document.getElementById('delete-booking');
const deleteMessageBtn = document.getElementById('delete-message');
const orderBtn = document.getElementById('book-product');
const bookBtn = document.getElementById('stripe-product');
 


//DELEGATION
if (loginForm)
  loginForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    login(email, password);
});


if (signupForm)
    signupForm.addEventListener('submit', e => {
      e.preventDefault();
      const name = document.getElementById('name').value;
      const email = document.getElementById('email').value;
      const password = document.getElementById('password').value;
      const passwordConfirm = document.getElementById('passwordConfirm').value;
      const shippingAddress = document.getElementById('shippingAddress').value;
      const phoneNumber = document.getElementById('phoneNumber').value;
      
  
      signup(name, email, password, passwordConfirm, shippingAddress, phoneNumber);
});


if (logOutBtn) logOutBtn.addEventListener('click', logout); 


if (deleteBookingBtn)
  deleteBookingBtn.addEventListener('click', e => {
  e.target.textContent = 'deleting...';
  const { bookingId } = e.target.dataset;

  deleteBtn('bookings', bookingId);
});

if (deleteMessageBtn)
  deleteMessageBtn.addEventListener('click', e => {
  e.target.textContent = 'deleting...';
  const { messageId } = e.target.dataset;

  deleteBtn('messages', messageId);
});

if(createProdForm)
  createProdForm.addEventListener('submit', e =>{
    e.preventDefault();
    const name = document.getElementById('prod-name').value;
    const price =  document.getElementById('prod-price').value;
    const summary = document.getElementById('prod-summary').value;
    const description = document.getElementById('prod-description').value;
    const imageCover = document.getElementById('prod-image').value;
    
    createProd(name, price, summary, description, imageCover);
  });

if(msgForm)
  msgForm.addEventListener('submit', e =>{
    e.preventDefault();
    const name = document.getElementById('msg-name').value;
    const email = document.getElementById('msg-email').value;
    const subject = document.getElementById('msg-subject').value;
    const message = document.getElementById('msg-message').value;

    messageForm(name, email, subject, message);
  });  
  

if(reviewForm)
  reviewForm.addEventListener('submit', e =>{
    const review = document.getElementById('message').value; 
    const productId = e.target.dataset.productId;
  
    reviewProd(productId, review);
  });
  

  if(orderBtn)
  orderBtn.addEventListener('click', e=>{
    e.target.textContent = 'Processing...';
    const { productId } = e.target.dataset;

    placeOrder(productId);
  });

  
if (bookBtn)
bookBtn.addEventListener('click', e => {
  e.target.textContent = 'Processing...';
  const { stripeproductId } = e.target.dataset;

  bookProduct(stripeproductId);
}); 

const alertMessage = document.querySelector('body').dataset.alert;
if (alertMessage) showAlert('success', alertMessage, 20);