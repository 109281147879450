import axios from 'axios';
import { showAlert } from './alert';

export const signup = async (name, email, password, passwordConfirm, shippingAddress, phoneNumber) => {  
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/signup',
      data: { 
        name,
        email,
        password,
        passwordConfirm,
        shippingAddress,
        phoneNumber
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Welcome, You are successfully signed up');
      window.setTimeout(() => {
        location.assign('/account');
      }, 1000);
    }
  } catch (err) {
    showAlert('error', 'Something Went Wrong! Try again');
  }
};